import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-inverse navbar-expand-lg">
      <Link className="navbar-brand" to="/">
        <img src="logo.jpg" alt="Logo" className="img-responsive" />
      </Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/parcours">PARCOURS</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/activite">ACTIVITE</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">CONTACT</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
