import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home'; 
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import Activite from './pages/Activite';
import Parcours from './pages/Parcours';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery/dist/jquery.min.js';

import './assets/css/font-awesome.min.css';
import './assets/css/bootstrap-theme.css';
import './assets/css/main.css';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/activite" element={<Activite />} />
          <Route path="/parcours" element={<Parcours />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
