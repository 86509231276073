// Page2.js
import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';

function Activite() {
  return (
    <>
      <Navbar />
      <section id="notfound" className="notfound section-bg">
        <Container>
          <Row className="my-4">
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={8}>
              <h1 className="my-4">Grille Tarifaire</h1>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Bilan Psychopédagogique</td>
                    <td className="tarif">150 €</td>
                  </tr>
                  <tr>
                    <td>Séance de Psychopédagogie</td>
                    <td className="tarif">55 €</td>
                  </tr>
                  <tr>
                    <td>Séance d'Anamnèse</td>
                    <td className="tarif">75 €</td>
                  </tr>
                  <tr>
                    <td>Interventions en Structures Éducatives et Culturelles</td>
                    <td className="tarif">Sur devis</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
          <Row className="my-4">
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={4}>
              <h2 className="my-4">Exemples d'interventions :</h2>
              <ul>
                <li>Ateliers en établissements scolaires</li>
                <li>Formations pour enseignants et éducateurs</li>
                <li>Séminaires et conférences</li>
                <li>Programmes d'accompagnement personnalisés</li>
              </ul>

              <h2 className="my-4">Conditions Générales</h2>
              <ul>
                <li><strong>Durée des séances :</strong> Les séances individuelles durent généralement 45 à 60 minutes.</li>
                <li><strong>Annulation :</strong> Toute annulation de séance doit être signalée au moins 24 heures à l'avance. À défaut, la séance pourra être facturée.</li>
                <li><strong>Paiement :</strong> Les paiements peuvent être effectués par chèque, virement bancaire ou en espèces.</li>
              </ul>
              <h2>Informations Complémentaires</h2>
              <p>Pour toute demande spécifique ou pour établir un devis pour des interventions en structures éducatives et culturelles, merci de bien vouloir contacter notre cabinet.</p>
            </Col>
            <Col xs={12} md={4}>
              <img src='./assets/images/anissa_activite.jpg' alt="Psychopédagogue en séance" className="img-fluid" />
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
          <Row className="my-4">
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={3}>
              <img src="./assets/images/activite-1.jpg" alt="Activité 1" className="img-fluid mb-4" />
            </Col>
            <Col xs={12} md={3}>
              <img src="./assets/images/activite-3.jpg" alt="Activité 3" className="img-fluid mb-4" />
            </Col>
            <Col xs={12} md={3}>
              <img src="./assets/images/activite-2.png" alt="Activité 2" className="img-fluid mb-4" />
            </Col>
            <Col xs={12} md={1}></Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Activite;
