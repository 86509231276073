import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';

function Home() {
  return (
    <>
      <Navbar />

      <header id="head">
        <div className="container">
          <div className="row">
            <h1 className="lead">Réussir ensemble avec la psychopédagogie.</h1>
            <p className="tagline">Psychopédagogie : Découvrir, Apprendre, Réussir.</p>
            <p>
              <Link className="btn btn-default btn-lg" to="/activite" role="button">Activité</Link>
              {' '}
              <Link className="btn btn-action btn-lg" to="/contact" role="button">Nous contacter</Link>
            </p>
          </div>
        </div>
      </header>

      <div className="container text-center">
        <br /> <br />
        <h2 className="thin">À Pavillons-sous-Bois, votre épanouissement est notre priorité grâce à une approche psychopédagogique personnalisée.</h2>
        <p className="text-muted">
          Nous sommes à votre écoute pour toute question ou prise de rendez-vous. Notre équipe de spécialistes en psychopédagogie est dédiée à vous fournir le soutien et l'accompagnement nécessaires pour surmonter les défis d'apprentissage et de développement personnel.
        </p>

        <div className="fb-section">
          <h3>Rejoignez-nous notre association Troubles Et Nous sur Facebook</h3>
          <a href="https://www.facebook.com/people/Troubles-Et-Nous/100070950050238/" className="btn btn-primary" target="_blank" rel="noopener noreferrer">
            Troubles Et Nous
          </a>
          <div className="fb-like" data-href="https://www.facebook.com/people/Troubles-Et-Nous/100070950050238/" data-width="" data-layout="standard" data-action="like" data-size="large" data-share="true"></div>
        </div>
      </div>

      <div className="jumbotron top-space">
        <div className="container">
          <h3 className="text-center thin">Pourquoi choisir la psychopédagogie pour transformer l'apprentissage ?</h3>

          <div className="row">
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption"><h4><i className="fa fa-heart fa-5"></i>Approche Personnalisée</h4></div>
              <div className="h-body text-center">
                <p>La psychopédagogie permet de comprendre les besoins individuels de chaque apprenant. En combinant des techniques psychologiques et pédagogiques, elle offre une approche sur mesure qui respecte le rythme et le style d'apprentissage de chacun. Cette personnalisation favorise une meilleure assimilation des connaissances et un développement optimal des compétences.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption"><h4><i className="fa fa-flash fa-5"></i>Renforcement de la Motivation</h4></div>
              <div className="h-body text-center">
                <p>La psychopédagogie aide à identifier et à lever les barrières psychologiques à l'apprentissage, telles que l'anxiété ou le manque de confiance en soi. En travaillant sur ces aspects, elle renforce la motivation et l'engagement des apprenants, ce qui se traduit par une plus grande persévérance et de meilleurs résultats scolaires.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption"><h4><i className="fa fa-cogs fa-5"></i>Compétences Polyvalentes</h4></div>
              <div className="h-body text-center">
                <p>Au-delà des connaissances académiques, la psychopédagogie se concentre sur le développement de compétences transversales essentielles, telles que la gestion du stress, la résolution de problèmes et la pensée critique. Ces compétences sont cruciales pour la réussite tant scolaire que professionnelle, et contribuent à former des individus adaptables et résilients.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 highlight">
              <div className="h-caption"><h4><i className="fa fa-smile-o fa-5"></i>Promotion du Bien-être</h4></div>
              <div className="h-body text-center">
                <p>L'intégration de la psychopédagogie dans le processus d'apprentissage contribue également au bien-être global des apprenants. En prenant en compte leurs émotions et en offrant des stratégies pour gérer les difficultés, elle crée un environnement d'apprentissage plus sain et plus agréable. Un apprenant qui se sent bien est plus enclin à s'investir et à réussir dans ses études.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center top-space">Consulter notre Parcours</h2>
        <br />

        <div className="jumbotron top-space">
          <h4>Le parcours d'un psychopédagogue est riche et diversifié, intégrant des connaissances approfondies en psychologie et en pédagogie.</h4>
          <p className="text-right"><Link className="btn btn-primary btn-large" to="/parcours">voir plus</Link></p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Home;
