// Page2.js
import React from 'react';
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';

function Parcours() {
  return (
    <>
      <Navbar/>
      <section id="notfound" className="notfound section-bg">

      <div className="container">
      <div className="row align-items-center">
        <div className="col-md-1"></div>
          <div className="col-md-6">
            <h1><b>Parcours Académique et Professionnel</b></h1>
            <br />

            <p>
            Mon parcours académique et professionnel reflète une continuité logique vers la psychopédagogie, motivée par des expériences personnelles et professionnelles significatives.
            <br />
            <br />
              <ul>
                <li>Maîtrise en Psychologie Cognitive (Université de Paris 8, Saint-Denis, 2004)</li>
                <li>Master  en Sciences de l'Éducation (Université de Paris 8, Saint-Denis, 2006)</li>
                <li>DU en Sciences Cognitives pour l'Éducation et la formation (Université de Poitiers, 2024)</li>
                <li>Ces diplômes m'ont doté d'une compréhension approfondie des mécanismes cognitifs et des dynamiques éducatives, fondement essentiel pour une carrière en psychopédagogie. </li>
              </ul>

            </p>
            <h2>Expérience Professionnelle</h2>
            <br />
            <p>
              <ul>
                <li>
                  Enseignante Certifiée de l'Education Nationale et Formatrice GRETA (Depuis 2006)
                </li>
                <ul>
                  <li>Formation des professionnels en petite enfance et carrières sanitaires et sociales</li>
                  <li>Conseillère Pédagogique en Éducation Inclusive</li>
                  <li>Soutien pédagogique pour l'inclusion scolaire</li>
                </ul>
              </ul>
            </p>

          </div>
          <div className="col-md-5">
            <img src='./assets/images/parcours-micro.jpg' alt="psychopédagogue parlant au micro" style={{ width: '80%', height: 'auto' }} />
          </div>  
        </div>
        <div className="row align-items-center">
        <div className="col-md-1"></div>
        <div className="col-md-5">
          <img src='./assets/images/parcours-classe.jpg' alt="psychopédagogue dans une classe" style={{ width: '80%', height: 'auto' }}/>
        </div>  

          <div className="col-md-6">
            <h2>Engagements et Contributions</h2>
            <br />
            <p>
              En tant que maman d'un enfant atypique, mon engagement personnel a enrichi mon parcours professionnel. Cette expérience m'a sensibilisée aux défis des troubles neurodéveloppementaux (TND) et a renforcé ma détermination à œuvrer pour une éducation inclusive.
            <br />
            <br />
            <ul>
              <li>
                Présidente de l'Association "Troubles et Nous" (Depuis 2021)
              </li>
              <ul>
                  <li>Sensibilisation et accompagnement des familles et professionnels sur les TND</li>
              </ul>
            </ul>
            <ul>
              <li>
                Formation UNESS sur les TND (2023)
              </li>
              <ul>
                  <li>Renforcement de mes compétences sur les TND</li>
              </ul>
            </ul>
            <ul>
              <li>
                Intervenante Culturelle et Éducative (Depuis 2023)
              </li>
              <ul>
                  <li>Réseau des Alliances Françaises à l'étranger</li>
              </ul>
            </ul>

            <br>
            </br>

            Ces engagements témoignent de ma volonté de partager mes connaissances et d'apporter un soutien concret aux familles et professionnels.

            </p>
          </div>
        </div>
        <div className="row align-items-center">
        <div className="col-md-1"></div>
        <div className="col-md-10">

        <h2>les champs d'intervention en tant que psychopédagogue</h2>
        <br />
          <ul>
              <li>
                Accompagnement des Apprentissages
              </li>
              <ul>
                  <li>Le psychopédagogue aide les enfants, adolescents et adultes à surmonter leurs difficultés d'apprentissage. Cela inclut des stratégies pour améliorer la concentration, la mémorisation, l'organisation du travail, la gestion du stress lié aux études et l'acquisition de méthodes de travail efficaces.
                  </li>
              </ul>
            </ul>
            <ul>
              <li>
                Gestion des Troubles du Comportement et des Émotions
              </li>
              <ul>
                  <li>
                    Le psychopédagogue intervient auprès des élèves présentant des troubles du comportement ou des difficultés émotionnelles qui interfèrent avec leur parcours scolaire. Il travaille sur des aspects tels que la régulation des émotions, l'estime de soi, la motivation scolaire, et la gestion des relations sociales.
                  </li>
              </ul>
            </ul>
            <ul>
              <li>
                Orientation et Projet de Vie
              </li>
              <ul>
                  <li>
                    Aider les jeunes et les adultes à définir et à construire leur projet professionnel et personnel. Cela inclut des bilans d'orientation, l'exploration des intérêts et des aptitudes, ainsi que l'accompagnement dans les choix de parcours scolaires ou professionnels.
                  </li>
              </ul>
            </ul>
            <ul>
              <li>
                Support aux Familles et aux Enseignants
              </li>
              <ul>
                  <li>
                    Le psychopédagogue offre des conseils et un soutien aux parents et aux enseignants pour mieux comprendre et gérer les difficultés éducatives et comportementales des enfants. Cela peut inclure des ateliers, des formations, et des consultations individuelles pour développer des stratégies éducatives adaptées.
                  </li>
              </ul>
            </ul>

          </div>
          <div className="col-md-1"></div>

          </div>
          </div>
          </section>
      <Footer />
    </>
  );
}

export default Parcours;
